import hapartment from "../assets/projects/hapa.png";
import { v4 as uuidv4 } from 'uuid';

const projects = [
  {
    id: uuidv4(),
    // order: 21,
    title: "KAWA RIDERS MOBILE APP (APPLESTORE)",
    stack: "REACTNATIVE, TYPESCRIPT, REDUX, PUSH NOTIFICATION, FIREBSE, LOCATION, MAP",
    description: "The Kawa Rider's App is a powerful tool designed exclusively for the logistics company's riders. With this user-friendly app, riders can effortlessly access their assigned orders, pick them up, and seamlessly navigate through the delivery process.",
    github: "https://github.com/Ayodejioladimeji",
    category: "mobile",
    url: "https://apps.apple.com/ph/app/kawa-rider/id6470408232",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1711214288/Screenshot_2024-03-23_at_6.16.38_PM_fnskdk.png",
    featured: true,
  },

  {
    id: uuidv4(),
    // order: 21,
    title: "KAWA - LAST MILE DELIVERY MOBILE APP",
    stack: "REACTNATIVE, TYPESCRIPT, REDUX, PUSH NOTIFICATION, FIREBSE, LOCATION, MAP ",
    description: "KAWA is a pioneering logistics aggregator company that bridges the gap between individuals, businesses, and reliable delivery solutions in Nigeria.",
    github: "https://github.com/Ayodejioladimeji",
    category: "mobile",
    url: "https://play.google.com/store/apps/details?id=ng.kawa.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1711213607/Screenshot_2024-03-23_at_6.06.11_PM_ljw0m2.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 21,
    title: "KAWA RIDERS MOBILE APP",
    stack: "REACTNATIVE, TYPESCRIPT, REDUX, PUSH NOTIFICATION, FIREBSE, LOCATION, MAP",
    description: "The Kawa Rider's App is a powerful tool designed exclusively for the logistics company's riders. With this user-friendly app, riders can effortlessly access their assigned orders, pick them up, and seamlessly navigate through the delivery process.",
    github: "https://github.com/Ayodejioladimeji",
    category: "mobile",
    url: "https://play.google.com/store/apps/details?id=ng.kawa.rider&hl=en&pli=1",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1711213609/Screenshot_2024-03-23_at_6.06.23_PM_tg3dw7.png",
    featured: true,
  },

  {
    id: uuidv4(),
    // order: 21,
    title: "WealthHat",
    stack: "NEXTJS,TYPESCRIPT,CONTEXT-API, SASS ",
    description: "An investment advisor that’s focused on you",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://www.wealthhat.com",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1711024564/Screenshot_2024-03-21_at_1.35.36_PM_hmv4ad.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 22,
    title: "KAWA HOMEPAGE",
    stack: "NEXTJS,TYPESCRIPT, SASS, SOCKET.IO ",
    description:
      "We bring you the opportunity to get the best delivery service by offering you different carrier services that suits your package, budget, location",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://www.kawa.ng",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1711210673/Screenshot_2024-03-23_at_4.55.09_PM_mf7img.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 22,
    title: "KAWA USER DASHBOARD",
    stack: "NEXTJS,TYPESCRIPT, SASS, SOCKET.IO, ",
    description:
      "From the convenience of your own device, you will be able to keep up-to-date with the status of your orders and enjoy a worry-free experience.",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://www.app.kawa.ng",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1711210672/Screenshot_2024-03-23_at_4.54.49_PM_vjvzur.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 22,
    title: "KAWA PARTNERS",
    stack: "NEXTJS,TYPESCRIPT, SASS, SOCKET.IO ",
    description:
      "Streamline your shipping, tracking, and fulfilment processes with ease and efficiency. Embrace the power of Kawa and elevate your logistics game today!",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://www.partners.kawa.ng",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1711210673/Screenshot_2024-03-23_at_4.54.28_PM_e5gpwj.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 22,
    title: "STUDIO4852",
    stack: "NEXTJS,TYPESCRIPT, SASS ",
    description:
      "Bringing Your Vision to Life with Our Expertise for Limitless Digital Possibilities",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://www.studio4852.com",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1711026504/Screenshot_2024-03-21_at_2.07.37_PM_l6nlqt.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 24,
    title: "Hapartment Mobile App",
    stack: "REACT NATIVE, TYPESCRIPT, REDUX ",
    description:
      "Hapartment is bringing agents with renters together by providing them with the simplest and most cost-effective route to renting properties online",
    github: "https://github.com/Ayodejioladimeji",
    category: "mobile",
    url: "https://play.google.com/store/apps/details?id=com.layobright.hapartment",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1711214292/Screenshot_2024-03-23_at_6.15.54_PM_lqcmnd.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 23,
    title: "Hapartment API Documentation",
    stack: "SWAGGER",
    description:
      "Documentation still in progress -- Hapartment is bringing agents with renters together by providing them with the simplest and most cost-effective route to renting properties online",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://api.hapartment.org",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1672945253/hapartment/hap_swagger_hvfk6z.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 22,
    title: "Hapartment",
    stack: "NEXTJS,TYPESCRIPT,REACT-REDUX,REDUX,SASS",
    description:
      "We help users search for apartment easily and provide them with adequate information to help them make well-informed decisions.",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://hapartment.org",
    image: hapartment,
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 22,
    title: "MedTech Solutions",
    stack: "NEXTJS,TYPESCRIPT,SASS ",
    description:
      "Empowering patients, enabling global interoperability of medical data, and strengthening international healthcare collaborating and governance through our secure and digitised ecosystem",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://hapartment.org",
    image: `https://res.cloudinary.com/devsource/image/upload/v1711026585/Screenshot_2024-03-21_at_2.09.28_PM_gzj5ki.png`,
    featured: true,
  },

  {
    id: uuidv4(),
    // order: 20,
    title: "lakesi Global Travel",
    stack: "REACT, CONTEXT-API, CSS, CHATWOOT, SEO ",
    description:
      "To make travel and vacation plans simple, affordable, and enjoyable by providing our customers with world-class professionally managed skills in travel and tourism.",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://lakesiglobaltravel.com",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1656504175/portfolio/project-three_lagph7.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 19,
    title: "Connects Chat App",
    stack:
      "REACT, REDUX, CSS, SOCKET.IO, NODE, HEROKU, PWA, EXPRESS.JS, STYLED COMPONENTS ",
    description:
      "Connect Chat App is a social messenger for connecting people together without stress",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://connects-chat.netlify.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1656504175/portfolio/project-two_dlwqol.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 18,
    title: "Teachify",
    stack:
      "REACT,CONTEXT-API, CSS, FRAMER-MOTION, NODE, PWA, EXPRESS.JS, BOOTSTRAP ",
    description:
      "Teachify is a platform where students will learn a particular available course at a time and after completing the course, student will be eligible for online assessment and after completing the exam, a completion certificate will be automatically...",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://teachify-learning.netlify.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1656504181/portfolio/project-one_jvk8lz.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 17,
    title: "Mr Adeyinka",
    stack: "REACT, CSS, FRAMER-MOTION, NODE, HEROKU, EXPRESS.JS ",
    description:
      "A platform that allows students to make complains, ask for advices, seek solutions, guildance and stay updated with school informations, it is also a platform that allows interactions between student of all levels or departments.",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://github.com/Ayodejioladimeji",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1632728745/portfolio/newly_aphvcc.png",
  },
  {
    id: uuidv4(),
    // order: 16,
    title: "Voting2023",
    stack: "REACT, CSS, FRAMER-MOTION, NODE, HEROKU, NETLIFY",
    description:
      "Voting2023 is an online voting application to securely store casted votes while preventing any form of tampering and multifactor authentication to verify eligibility while granting voters to easily securely cast their votes using various mobile devices from any location while also increasing votes trust towards the voting process and offer better transparency during voting process.",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://github.com/Ayodejioladimeji",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1626899897/portfolio/Capturet_cxxlwk.png",
  },
  {
    id: uuidv4(),
    // order: 15,
    title: "LighthillNg",
    stack: "REACT, CSS, FRAMER-MOTION, REACT-ANIMATIONS, NODE, NODEMAILER ",
    description:
      "Lighthill Investment Company is a leading investment and financial company that provides a wide range of investment solutions, forex trading services, and real estate management. Our platforms are specifically designed to meet the needs of individual, corporate and institutional clients.",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://lighthillng.com",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1626899836/portfolio/Capturey_z5swu1.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 14,
    title: "Zuri Chat",
    stack: "REACT, CSS, FRAMER-MOTION, CHAT-ENGINE, STYLED-COMPONENT, NETLIFY",
    description:
      "Zuri chat is a multiple chat platform for group conversation and private chats, Zuri chat provides enough privacy for you and your team to collaborate together and discuss solutions. With Zuri chat you can send live messages and receive text asynchronously...",
    github: "https://github.com/Ayodejioladimeji/chat-app",
    category: "web",
    url: "https://zurichat.netlify.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1620538067/Screenshot_2021-05-09_at_6.25.39_AM_txpfzy.png",
  },
  {
    id: uuidv4(),
    // order: 13,
    title: "MAMAZEE CLOTHING",
    stack: "REACT,CONTEXT-API, CSS, NODE-JS, BOOTSTRAP, HEROKU-APP",
    description:
      "Mamazee is an ecommerce website where you can buy unisex clothing online. Mamazee provides Faster buying process store and product listing creation, cost reduction, Affordable advertising and marketing, Flexibility for customers, No reach limitations, Product and price comparison, Faster response to buyer/market demands.",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://mamazee.netlify.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948495/portfolio/admn_ubw1pr.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 12,
    title: "THE LIGHT CHURCH OF CHRIST OLUWANISOLA",
    stack: "REACT, CSS, BOOTSTRAP, NETLIFY, SENDGRID",
    description:
      "To evangelize the world through the proclamation of his word. The good news of Jesus Christ and the kingdom of God in the name of Jesus Christ. To proclaiming deliverance to those who are bound by the shackles of Satan, healing the broken hearted, the sick, the informed, restoring hope to the hopeless while reinforcing the faith of all - both feeble and strong in the unfailing righteousness and faithfulness of God.",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "https://oluwanisola.org",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1632738347/church/oluwa_nn146p.png",
    featured: true,
  },
  {
    id: uuidv4(),
    // order: 11,
    title: "DAILY SCHEDULER",
    stack: "HTML, CSS, JAVASCRIPT, PHP, MYSQL, WEBHOST",
    description:
      "Daily scheduler is a web applications that allows you to keep your daily tasks intact, properly and orderly.",
    github: "https://github.com/Ayodejioladimeji",
    category: "web",
    url: "http://todo-tasks.000webhostapp.com/",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948384/portfolio/todo_c8rn7a.png",
  },
  {
    id: uuidv4(),
    // order: 10,
    title: "CRYPTO TRACKER",
    stack: "REACT, CSS , BOOTSTRAP, NETLIFY",
    description:
      "Crypto Tracker is a cryptocurrency web application for tracking cryptocurrency prices, crypto tracker produces accurate data which bicoin users can rely on. ",
    github: "https://github.com/Ayodejioladimeji/crypto_tracker",
    category: "web",
    url: "https://cryptotrackers.netlify.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948382/portfolio/tra_fixw7x.png",
  },
  {
    id: uuidv4(),
    // order: 9,
    title: "HOME AND AWAY",
    stack: "REACT, CSS , BOOTSTRAP, STYLED-COMPONENT, NETLIFY",
    description:
      "Home and Away is a relaxation place, where you can enjoy your holiday with friends and families, Home and Away has beautiful and condusive facilities that will make you comfortable throughout your stay ",
    github: "https://github.com/Ayodejioladimeji/Home-and-Away",
    category: "web",
    url: "https://homeandaway.netlify.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948374/portfolio/away_fqubqi.png",
  },
  {
    id: uuidv4(),
    // order: 8,
    title: "BUY.COM",
    stack: "REACT, CSS , BOOTSTRAP, STYLED-COMPONENT, NETLIFY",
    description:
      "Buy.com is an online portals that facilitate online transactions of goods and services through means of the transfer of information and funds over the Internet. You can purchase any series of mobile devices of your choice irrespective of the brands and manufacturers",
    github: "https://github.com/Ayodejioladimeji/buy.com",
    category: "web",
    url: "https://buydotcom.netlify.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948375/portfolio/buy_gewjkt.png",
  },
  {
    id: uuidv4(),
    // order: 7,
    title: "WEATHER TRACKER",
    stack: "HTML, CSS, JAVASCRIPT, JQUERY, NETLIFY",
    description:
      "Weather Tracker is a web app for tracking weather conditions all over the world. Weather Tracker gives accurate results using Geo-Locator to acccess users location.",
    github: "https://github.com/Ayodejioladimeji/Javascript-Weather-Tracker",
    category: "web",
    url: "https://my-weather-tracker.netlify.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948383/portfolio/tracker_wafyjj.png",
  },
  {
    id: uuidv4(),
    // order: 6,
    title: "SAKINWUMI LIMITED -- still under construction",
    stack: "GATSBY, CSS , GRID-SYSTEM, NETLIFY",
    description:
      "Sakinwumi Limited is a Farming investment Limited that feeds the world, Sakinwumi farms produces fresh Tomatoes, Vegetables and also fresh fruits with different species. They are also into livestock production, and you can also get fresh eggs from them.",
    github: "https://github.com",
    category: "web",
    url: "https://sakinwumi.netlify.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948387/portfolio/same_w2i8lf.png",
  },
  {
    id: uuidv4(),
    // order: 5,
    title: "DEVCAREER V2",
    stack: "GATSBY, SASS, CSS , BOOTSTRAP, NETLIFY",
    description:
      "DevCareer is a Non-Profit organization that is focused on supporting upcoming developers with resources to excel into world class... Underdeveloped African countries are lacking when it comes to adequate resources to dive into Tech. DevCareer is focused on supporting these group of people.",
    github: "https://github.com",
    category: "web",
    url: "https://devcareer2.netlify.app/",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948376/portfolio/devcareer_suizgf.png",
  },
  {
    id: uuidv4(),
    // order: 4,
    title: "FREE VIDEOS MOBILE APP",
    stack: "REACTNATIVE",
    description:
      "Free videos mobile App gives access to unlimited video streaming, --Free videos is a youtube clone that gives free access to videos online, With free videos web app you can watch unlimited videos online including movies and latest sport..Providing your audience with a variety of ways to consume information – including text, video illustrations and audio cues – will increase the overall engagement your videos receive, as you are catering to different learning styles.",
    github:
      "https://github.com/Ayodejioladimeji/ReactNative-FreeVideos-Mobile-App",
    category: "web",
      url: "https://github.com/Ayodejioladimeji/ReactNative-FreeVideos-Mobile-App",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948382/portfolio/native_ea4lcx.png",
  },
  {
    id: uuidv4(),
    // order: 3,
    title: "FREE VIDEOS",
    stack: "REACT, CSS , BOOTSTRAP, NETLIFY",
    description:
      "Free videos is a youtube clone that gives free access to videos online, With free videos web app you can watch unlimited videos online including movies and latest sport..Providing your audience with a variety of ways to consume information – including text, video illustrations and audio cues – will increase the overall engagement your videos receive, as you are catering to different learning styles",
    github: "https://github.com",
    category: "web",
    url: "https://frevideos.netlify.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948383/portfolio/one_k7timq.png",
  },
  {
    id: uuidv4(),
    // order: 2,
    title: "MAMA ZEE",
    stack: "JAVASCRIPT, CSS , PHP, BOOTSTRAP , JQUERY ,WEBHOST",
    description:
      "Welcome to Mamazee wears, A live website where you can purchase series of latest unisex wears, Mama zee web app receives users order and proceed to delivery once payment has been made.Ecommerce benefits like being able to easily display best-sellers makes it easier to show off products to customers. While you can design a brick and mortar store to sway people to buy certain products, it’s easier for a customer to find the best-sellers in an online store ",
    github: "https://github.com",
    category: "web",
    url: "https://mamazee.000webhostapp.com",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948375/portfolio/mamazee_llqfnl.png",
  },
  {
    id: uuidv4(),
    // order: 1,
    title: "MOVIES APP",
    stack: "REACT, CSS , BOOTSTRAP, NETLIFY",
    description:
      "Movies App is a web app made with React, With movies app you can search for unlimited movies of your choice,Using any of the reliable apps to watch free movies online instantly eliminates the need to download the movies to a tablet, phone, laptop, or desktop computer..It Reduces The Cost Of Entertainment....It Is Convenient....It Allows For Multi-Device Access...It Gives Access to Content Databases. You have millions of movies waiting for you, click the link to explore...",
    github: "https://github.com",
    category: "web",
    url: "https://moviesfinders.netlify.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948380/portfolio/movies_rlvvls.png",
  },
  {
    id: uuidv4(),
    // order: 0,
    title: "WEB PORTFOLIO",
    stack: "HTML, JAVASCRIPT, CSS , SCSS, JQUERY",
    description:
      "My web portfolio which creatively showcase the narrative and arc of my entire professional career, illuminating both my accomplishments over time as well as my desired contributions and forecast potential. When created with intention, portfolios can weave together your core values, strengths, and talents in an integrated way with your experience, education and passion projects.",
    github: "https://github.com",
    category: "web",
    url: "https://ayodejioladimeji.netlify.app",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1614948984/portfolio/portfolio-page_kwffsu.png",
  },
];

export default projects;
