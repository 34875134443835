import formik from "../assets/projects/formik.png";
import formiks from "../assets/projects/formi.png";

const blog = [
  {
    id: "1",
    url: "https://blog.ayodejioladimeji.com/form-validation-in-react-with-formik",
    desc: "Building a Secure Client Side Form Validation",
    slug: "READ",
    image: formiks,
    title: "Form Validation in React with Formik",
    github: "https://github.com/Ayodejioladimeji",
  },
  {
    id: "2",
    url: "https://blog.ayodejioladimeji.com/mastering-form-validation-with-formik-and-advanced-password-requirements",
    desc: "Building a Secure Password Form validation with ReactJs",
    slug: "READ",
    image: formik,
    title:
      "Mastering Form Validation with Formik and Advanced Password Requirements",
    github: "https://github.com/Ayodejioladimeji",
  },
  {
    id: "3",
    url: "https://layobright.hashnode.dev/understanding-the-basics-of-object-oriented-programming-as-a-beginner",
    desc: "Understanding the basics of Object Oriented Programming as a Beginner",
    slug: "READ",
    image:
      "https://res.cloudinary.com/devsource/image/upload/v1656527339/portfolio/oop_uzeuhk.webp",
    title: "Object Oriented Programming",
    github: "https://github.com/Ayodejioladimeji",
  },
];

export default blog;
