const stack = [
  {
    id: "1",
    stack: "HTML",
  },
  {
    id: "2",
    stack: "CSS",
  },
  {
    id: "3",
    stack: "REACT TS",
  },
  {
    id: "13",
    stack: "NEXT TS",
  },
  {
    id: "14",
    stack: "TYPESCRIPT",
  },
  {
    id: "5",
    stack: "GATSBY",
  },
  {
    id: "4",
    stack: "REACTNATIVE",
  },
  {
    id: "11",
    stack: "GIT",
  },
  {
    id: "12",
    stack: "NODE JS",
  },
  {
    id: "13",
    stack: "EXPRESS_JS",
  },
  {
    id: "6",
    stack: "BOOTSTRAP",
  },
  {
    id: "7",
    stack: "PHP",
  },
  {
    id: "8",
    stack: "JAVASCRIPT",
  },
  {
    id: "9",
    stack: "JQUERY",
  },
  {
    id: "10",
    stack: "SQL",
  },

  {
    id: "14",
    stack: "MONGO_DB",
  },
  {
    id: "15",
    stack: "AWS",
  },
  {
    id: "16",
    stack: "HEROKU",
  },
  {
    id: "17",
    stack: "DIGITAL OCEAN",
  },
  {
    id: "17",
    stack: "SASS",
  },
  {
    id: "17",
    stack: "SOCKET",
  },
];

export default stack;
