const jobs = [
  {
    id: "8",
    order: 8,
    title: "Frontend Lead / Mobile Developer",
    dates: "JAN 2023 - TILL DATE",
    duties: [
      "Lead a team of 4 frontend developers, providing mentorship, guidance, and fostering a culture of excellence. Oversee project assignments, workload distribution, and performance evaluations.",
      "Write clean and efficient code that is maintainable and scalable.",
      "Drive the architectural decisions for web applications, ensuring scalability, maintainability, and adherence to best practices.",
      "Collaborate with UX/UI designers to implement user interfaces that provide an exceptional user experience. Ensure designs are pixel-perfect and responsive.",
      "Develop and maintain mobile applications for iOS and Android platforms using ReactNative",
      "Optimize frontend performance through techniques like code splitting, lazy loading, and image optimization. Conduct performance audits and implement improvements.",
      "Integrated the web application with third-party APIs such as Google Maps and Stripe.",
      "Prepare and submit apps to Apple App Store and Google Play Store, ensuring compliance with platform-specific guidelines and requirements.",
      "Collaborate with backend developers, product managers, and QA teams to deliver end-to-end solutions. Ensure seamless integration between frontend and backend systems.",
      "Participated in daily stand-up meetings and sprint planning sessions.",
      "Integrate third-party services, such as social media APIs, analytics, and payment gateways, as required by the app.",
      "Create and maintain technical documentation, including architecture diagrams, coding standards, and best practices.",
      "Participate in and conduct code reviews to maintain code quality and consistency within the development team.",
      "Stay up-to-date with the latest frontend technologies and trends. Propose and implement innovative solutions to improve user experiences.",
    ],
    company: "TheFoundersLab",
  },
  {
    id: "7",
    order: 7,
    title: "Frontend Developer",
    dates: "AUG 2022 - DEC 2022",
    duties: [
      "Use markup languages like HTML to create user-friendly web pages",
      "Write functional requirement documents and guides",
      "Ensure high quality graphic standards and brand consistency",
      "Optimizing web pages for maximum speed and scalability.",
      "Striking a balance between functional and aesthetic design.",
      "Implement visual elements that users see and interact with in a web application.",
      "Developing features to enhance the user experience.",
      "Maintaining brand consistency throughout the design.",
    ],
    company: "FreeLancing",
  },
  {
    id: "6",
    order: 6,
    title: "Project Lead Developer",
    dates: "MAR 2022 - JUNE 2022",
    duties: [
      "Work in a multidisciplinary team with other professionals such as backend developers and web designers",
      "Worked as a project lead to implement 92% of business requirements and built a useful system to drive results",
      "Always stay consistent with our brand guidelines and test the website for usability",
      "Bridged the gap between graphic design and technical implementation by working on both sides and helping define 16+ applications' look and feel",
      "Make suggestions for better solutions to problems and ensure browser compatibility",
      "Implemented web performance optimizations and ensured web pages conformed to accessibility standards",
      "Translated 10+ UI/UX designs to code resulting in the visual elements of the application",
    ],
    company: "Ultainfinity GG",
  },
  {
    id: "5",
    order: 5,
    title: "Frontend Developer",
    dates: "JULY 2021 - DEC 2021",
    duties: [
      "Collaborating closely with the team to support projects during all phases of delivery.",
      "Identifying innovative ideas and proof of concepts according to project requirements.",
      "Communicating technical issues in an understandable manner to the team",
      "Maintaining an organized workflow using a project management tool (like GitHub).",
      "Monitoring our web app performance and rectifying front-end-related issues.",
      "Testing to identify bugs and technical issues before and after deploying.",
    ],
    company: "TiideLab",
  },
  {
    id: "4",
    order: 4,
    title: "Fullstack Developer",
    dates: "MAY 2021 - OCT 2021",
    duties: [
      "Responsible for end-to-end web app development and creative backend engineering",
      "Developed a full-stack web applications which processed analyzed and rendered data visually.",
      "Managed time-sensitive updates, including content changes and database updates",
      "work with a team of creative designers and developers to build interfaces and meet objectives.",
    ],
    company: "Lighthillng",
  },
  {
    id: "3",
    order: 3,
    title: "Frontend Developer",
    dates: "AUG 2020 - APRIL 2021",
    duties: [
      "Collaborated with Developers across Africa to work on projects using variety of tools which includes-:HTML, Javascript, Bootstrap, Css, React and Gatsby.",
      "Manage complex details about projects that require analyzing design requirements.",
      "Recommending technical solutions to make projects scalable, maintainable and efficient",
      "Interpret requirements and create project plans to meet schedule and quality goals.",
    ],
    company: "Devcareer",
  },
  {
    id: "2",
    order: 2,
    title: "Web Developer(Intern)",
    dates: "AUG 2019 - JULY 2020",
    duties: [
      "Developed and structured feature-rich client websites, ensuring that all needs were met and company policies and procedures followed; created high-quality code.",
      "Collaborated with project managers and creative teams to deliver original, efficient solutions; advised clients and provided expertise.",
      "Collaborated with the team to design, structure, and implement new websites from start to finish; updated existing websites.",
      "Developed a user-friendly architecture; provided support and customer service; trained and educated staff.",
    ],
    company: "Dots ict",
  },
  {
    id: "1",
    order: 1,
    title: "Frontend Intern",
    dates: "FEB 2019 - JUNE 2019",
    duties: [
      "Participated in weekly meetings with executives, supported brainstorming and idea generation",
      "Managed complex projects from start to finish",
      "Collaborated with other designers",
      "Translated requirements into polished, high-level designs",
    ],
    company: "SI Lab",
  },
];

export default jobs;
