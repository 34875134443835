import image22 from "../assets/images/kawa.jpeg";

const about = [
  {
    id: "1",
    title: "About Me",
    info: [
      "Object Oriented Frontend developer with four (4) years of experience building and maintaining responsive web App in the recruiting industry.",

      "As a developer, I try to create things that no one else has ever created before.",
      "I use algorithms, patterns and engineering principles to craft and mold products into something that customers will love",

      "I care deeply about the code I write and the effect my work has on the client’s product.",
      " I enjoy interactions with clients and seeing the joy they get when I deliver the applications they’ve been wanting.",
    ],
    image: image22,
  },
];

export default about;
