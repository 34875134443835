const ACTIONS = {
  REMOVE_NAV: "REMOVE_NAV",
  REMOVE_TAB: "REMOVE_TAB",
  OPEN_MODAL: "OPEN_MODAL",
  LANGUAGE_OPEN_MODAL: "LANGUAGE_OPEN_MODAL",
  CREATE_REVIEW: "CREATE_REVIEW",
  MENU_OPEN: "MENU_OPEN",
};

export default ACTIONS;
